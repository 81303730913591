import Swiper, { Autoplay } from 'swiper';
Swiper.use([Autoplay]);

// eslint-disable-next-line no-unused-vars
let descCarrousel;

document.addEventListener('DOMContentLoaded', () => {
	descCarrousel = new Swiper('.carrousel__desc', {
		slidesPerView: 1,
		spaceBetween: 0,
		watchOverflow: true,
		autoplay: {
			delay: 2500,
			disableOnInteraction: false,
		},
		breakpoints: {
			576: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
});
